import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Reference from '../components/reference'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "reference.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Reference" />

      <Hero
        title="Reference"
        subtitle="Tým našich pracovníků má za dobu naší činnosti bohaté zkušenosti a značný počet realizovaných zakázek"
        image={data.image}
      />

      <Reference />
    </Layout>
  )
}

export default IndexPage
