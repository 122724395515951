import React from 'react'
import { Box } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import Cards from './cards'

const cards = [
  {
    html:
      '<strong>MONTÁŽE A REKONSTRUKCE plynových kotelen</strong> v Ostravě, Novém Jičíně, Kopřivnici, Světlé Hoře, Valašském Meziříčí, Rýmařově, Halenkově, Vysokém Mýtě, Velkém Karlově, Havlíčkově Brodu, Brně, Praze…',
  },
  {
    html: `<strong>MONTÁŽE všech typů ústředního vytápění, zdravotechniky a plynoinstalací na území České republiky.</strong> MAKRO Praha, MAKRO Ostrava, MAKRO Brno, KB Nový Jičín, IKEA Brno...`,
  },
  {
    html: `<strong>REKONSTRUKCE A MONTÁŽE všech typů výměnikových stanic</strong> v Ostravě, Kopřivnici, Frýdku-Místku, Zlíně, Sezimově Ústí a Uherském Hradišti.`,
  },
  {
    html: `<strong>DODÁVKY A MONTÁŽE NTL a STL plynovodů</strong> v Ostravě, Novém Jičíně, Kopřivnici, Rožnově pod Radhoštěm, Frenštátě pod Radhoštěm a Valašském Meziříčí.`,
  },
  {
    html: `<strong>MONTÁŽE A VÝMĚNY sekundárních rozvodů tepla</strong> v Ostravě, Novém Jičíně, Plzni, Brně, Praze, Uherském Hradišti, Opavě, Dvorcích, Velkém Karlově, Svitavách, Frýdku-Místku, Zlíně, Havířově, Jaroměři včetně provedení tzv. bezkanálového uložení z předizolovaného potrubí.`,
  },
]

const AboutUs = () => {
  return (
    <Section>
      <Container>
        <Box>
          <Cards cards={cards} />
        </Box>
      </Container>
    </Section>
  )
}

export default AboutUs
