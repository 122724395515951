import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Section = ({ children, ...rest }) => {
  return (
    <Box variant="section" {...rest}>
      {children}
    </Box>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
