/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Text, Card, Image } from 'rebass/styled-components'

import AwardBadge from '../images/icons/award-badge.svg'
import Wrench from '../images/icons/wrench.svg'
import Plug from '../images/icons/plug.svg'
import Helmet from '../images/icons/helmet.svg'
import Arch from '../images/icons/arch.svg'

const icons = {
  AwardBadge,
  Wrench,
  Plug,
  Helmet,
  Arch,
}

const Cards = ({ cards, direction }) => {
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', direction],
        justifyContent: 'space-between',
      }}
    >
      {cards &&
        cards.map((card, i) => {
          const icon = icons[card.icon]

          return (
            <Card sx={{ mb: 3 }} key={`card-${i}`}>
              <Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                {icon && (
                  <Box
                    sx={{
                      mr: 3,
                      p: [2, 3, 3],
                      bg: 'primary',
                      borderRadius: 'circle',
                      lineHeight: 0,
                      flexShrink: 0,
                    }}
                  >
                    <Image
                      sx={{
                        maxWidth: '1.25rem',
                      }}
                      src={icon}
                      alt=""
                      aria-hidden
                    />
                  </Box>
                )}

                <Text
                  sx={{ color: 'text' }}
                  as="p"
                  dangerouslySetInnerHTML={{ __html: card.html }}
                />
              </Flex>
            </Card>
          )
        })}
    </Flex>
  )
}

Cards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
}

Cards.defaultProps = {
  direction: 'column',
}

export default Cards
